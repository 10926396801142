const leftMenu = [
  {
    title:'住房管理',
    index:'1',
    child:[
      {
        title:'小区管理',
        index:'1-1'
      },{
        title:'楼栋单元管理',
        index:'1-2'
      },{
        title:'住房管理',
        index:'1-3'
      },{
        title:'住户管理',
        index:'1-4'
      },{
        title:'审核列表',
        index:'1-5'
      },
    ]
  },
  // {
  //   title:'部门管理',
  //   index:'2',
  //   child:[
  //     {
  //       title:'部门列表',
  //       index:'2-1'
  //     },{
  //       title:'岗位列表',
  //       index:'2-2'
  //     },{
  //       title:'员工列表',
  //       index:'2-3'
  //     },{
  //       title:'离职记录列表',
  //       index:'2-4'
  //     }
  //   ]
  // },
  {
  	  title:'停车管理',
  	  index:'3',
  	  child:[
  		  {
  			  title: '停车场二维码创建',
  			  index:'3-1'
  		  },
  		  {
  			  title: '二维码生成记录',
  			  index:'3-2'
  		  },
		  {
			  title: '停车场缴费记录',
			  index:'3-3'
		  }
  	  ]
  }
]
export default {
  leftMenu
}