<template>
  <div class="home_wrap">
    <div class="aside">
      <div class="logo_wrap">
        <img class="img" src="../assets/logo.png">
      </div>
      <el-menu
      class="el-menu-vertical-demo"
      unique-opened
      background-color="#16347C"
      :default-active="defaultActive1">
        <el-submenu  v-for="(item,index) in leftMenu" :key="index" :index="item.index">
          <template slot="title">
            <span>{{item.title}}</span>
          </template>
          <el-menu-item-group>
            <el-menu-item v-for="(item1,index1) in item.child" :key="index1" :index="item1.index" @click="loadRightPage(item1.index)">{{item1.title}}</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
    </div>
    <div class="main" >
      <div class="top">
        <span class="left">欢迎登录{{xiaoQuName}}！</span>
        <span class="right">
          <span>{{account}}</span>
          <span @click="goToChangePwd" class="change_pwd">修改密码</span>
          <span @click="loginOut" class="login_out">退出</span>
        </span>
      </div>
      <div class="bottom">
        <div class="inner_bottom">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import leftMenuData from '@/util/leftMenu.js'
import {mapState} from "vuex";
import vm from '@/util/event.js'
import {getXiaoQuDetailsFn} from '@/api/xiaoQu.js'
export default {
  name: 'Home',
  data(){
    return{
      leftMenu:'',//左侧菜单
      defaultActive1:'',//当前激活的菜单index
      avater:'',
      account:'',
      xiaoQuName:''
    }
  },
  computed:{
    // ...mapState(['xiaoQuName'])
  },
  created(){
    this.leftMenu = leftMenuData.leftMenu
    this.defaultActive1 = localStorage.getItem('defaultActive1') ||  '1-1'
  },
  mounted(){
    this.getXiaoQuDetail()
  },
  methods:{
    getXiaoQuDetail() {
				getXiaoQuDetailsFn({
					id: this.id,
          api_token: localStorage.getItem('token1'),
				}).then(res => {
					this.xiaoQuName = res.datas.title;
          this.account = res.datas.account
				});
			},
    //去修改密码页面
    goToChangePwd(){
      this.$router.push({
        path:'/changePwd'
      })
    },
    //加载右侧页面
    loadRightPage(v){
      let self = this
      localStorage.setItem('defaultActive1',v)
      if (v == '1-1'){//小区
        this.$router.push('/editXiaoQu')
      }else if (v== '1-2'){//楼栋单元管理
        this.$router.push('/louDongList')
      }else if (v == '1-3'){//住房管理
        this.$router.push('/zhuFangList')
      }else if (v == '1-4'){//住户管理
        this.$router.push('/zhuHuList')
      } else if(v == '1-5'){//审核列表
        this.$router.push('/shenHeList')
      } else if(v == '2-1'){//部门列表
        this.$router.push('/departMentList')
      } else if(v == '2-2'){//岗位列表
        this.$router.push('/postList')
      } else if(v == '2-3'){//员工列表
        this.$router.push('/staffList')
      } else if(v == '2-4'){//离职列表
        this.$router.push('/quitList')
      } else if(v == '3-1'){//生成二维码
        this.$router.push('/parking')
      } else if(v == '3-2'){//二维码列表
        this.$router.push('/parkingList')
      } else if(v == '3-3'){//缴费记录
        this.$router.push('/cashRecord')
      }
    },
    //退出
    loginOut(){
      localStorage.removeItem('token1')
      this.$router.push('/login')
    },
  }
}
</script>

<style scoped lang="scss">
.home_wrap{
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  .aside{
    width: 200px;
    height: 100%;
    background-color: #16347C;
    color: #fff;
    .logo_wrap{
      padding-top: 20px;
      padding-bottom: 30px;
      width: 200px;
      text-align: center;
      img{
        // margin-left: 20px;
      }
    }
    .msg{
      padding: 0 5px;
      margin-left: 5px;
      box-sizing: border-box;
      background-color: red;
      box-shadow: 0 0 20px red;
      color: #fff;
      border-radius: 15px;
    }
  }
  .main{
    width: calc(100% - 200px);
    height: 100%;
    .top{
      width: calc(100vw - 200px);
      height: 60px;
      padding: 0 30px;
      box-sizing: border-box;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color:#fff;
      color: #000;
      .left{

      }
      .right{
        display: flex;
        align-items: center;
        .avater{
          display: inline-block;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 10px;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .change_pwd{
          margin-left: 10px;
          cursor: pointer;
        }
        .login_out{
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }
    .bottom{
      width: calc(100vw - 200px);
      height: calc(100vh - 60px);
      padding: 10px 0 0 10px;
      background-color: #eaeaec;
      box-sizing: border-box;
      overflow: hidden;
      .inner_bottom{
        width: calc(100vw - 210px);
        height:calc(100vh - 70px);
        background-color: #fff;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }
  }
}

</style>
